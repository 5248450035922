import classNames from 'classnames'

import MuiContainer, { ContainerProps } from '@mui/material/Container'
import styles from './container.module.scss'

type Props = ContainerProps & {
  children: React.ReactNode
  isCentered?: boolean
}

function Container({
  children,
  isCentered = false,
  maxWidth = 'xs',
  ...props
}: Props) {
  return (
    <MuiContainer
      maxWidth={maxWidth}
      {...props}
      className={classNames(
        { [styles.centered]: isCentered },
        styles.container,
        props.className
      )}
    >
      {children}
    </MuiContainer>
  )
}

export default Container
