import MuiList, { ListProps } from '@mui/material/List/List'
import ListItem, { ListItemBaseProps } from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'

import theme from 'styles/customTheme'

type Props = ListProps & {
  children?: React.ReactNode[]
  list?: string[]
  listItemStyles?: ListItemBaseProps['sx']
  listStyleType?: React.CSSProperties['listStyleType']
  isCondensedList?: boolean
}

function List({
  children,
  list,
  listItemStyles,
  listStyleType = 'disc',
  isCondensedList = false,
  ...props
}: Props) {
  const bodyStyles = theme.typography.body1

  return (
    <MuiList
      sx={{
        listStyleType,
        pl: 2,
        '& .MuiListItem-root': {
          display: 'list-item',
          px: 1,
          py: isCondensedList ? 0 : 0.5,
          ...bodyStyles,
        },
      }}
      {...props}
    >
      {children}
      {list?.map((item, index) => (
        <ListItem key={`li-${index}`} sx={{ ...listItemStyles }}>
          <Typography
            dangerouslySetInnerHTML={{
              __html: item,
            }}
          />
        </ListItem>
      ))}
    </MuiList>
  )
}

export default List
