import { useTranslation } from 'react-i18next'
import ArrowBack from '@mui/icons-material/ArrowBack'
import Link, { LinkProps } from './mui/Link'

type Props = LinkProps

function BackLink({ to = '/', onClick, ...props }: Props) {
  const { t } = useTranslation()

  return (
    <Link
      to={onClick ? undefined : to}
      onClick={onClick ?? undefined}
      variant="subtitle2"
      sx={{
        display: 'flex',
        alignItems: 'center',
        mb: 4,
        ...props.sx,
      }}
    >
      <ArrowBack sx={{ color: 'primary.main', mr: 1 }} />
      {t('common.back')}
    </Link>
  )
}

export default BackLink
