// eslint-disable-next-line no-restricted-imports
import { Formik, useFormikContext, Form as FormikForm } from 'formik'
import { useState, useEffect, ComponentProps } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useTranslation } from 'react-i18next'

import usePrompt from 'hooks/usePrompt'

function useFormikSync<T>(initialValues: T) {
  const [syncedFormikValues, setSyncedState] = useState(initialValues)

  const sync = (values: typeof initialValues) => {
    if (JSON.stringify(values) === JSON.stringify(syncedFormikValues)) return
    setSyncedState(values)
  }

  const SyncFormik = ({ values }: any) => {
    useDeepCompareEffect(() => {
      sync(values)
    }, [values])

    return null
  }

  return { syncedFormikValues, SyncFormik }
}

function ScrollToError() {
  const formik = useFormikContext()
  const submitting = formik?.isSubmitting

  useEffect(() => {
    const el = document.querySelector('.Mui-error, [data-error]')
    ;(el?.parentElement ?? el)?.scrollIntoView()
  }, [submitting])

  return null
}

function PromoptOnLeave() {
  const { dirty } = useFormikContext()
  const { t } = useTranslation()

  usePrompt(t('common.leavePage'), dirty)

  return null
}

interface FormProps extends ComponentProps<typeof FormikForm> {
  promptOnLeave?: boolean
}

const Form = ({ children, promptOnLeave = false, ...props }: FormProps) => (
  <FormikForm noValidate {...props}>
    <ScrollToError />
    {promptOnLeave && <PromoptOnLeave />}
    {children}
  </FormikForm>
)

// TODO: Enforce importing from `lib/formik` instead of directly from formik once all forms are migrated
export type { FormikProps, FormikHelpers } from 'formik'
export { FieldArray, useField } from 'formik'
export { Formik, useFormikContext, Form, useFormikSync }
