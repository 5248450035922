import { Route, Routes } from 'react-router-dom'

import ForgotPassword from 'views/user/ForgotPassword'
import Login from 'views/user/Login/Login'
import ResetPassword from 'views/user/ResetPassword'
import VerificationCode from 'views/user/VerificationCode'
import CreateProfile from 'views/user/CreateProfile/CreateProfile'
import PageNotFound from 'views/PageNotFound'
import ResponsiveHeaderBar from 'components/headerBar/ResponsiveHeaderBar'

function User() {
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route
        path="create"
        element={
          <ResponsiveHeaderBar>
            <CreateProfile />
          </ResponsiveHeaderBar>
        }
      />
      <Route
        path="forgot"
        element={
          <ResponsiveHeaderBar>
            <ForgotPassword />
          </ResponsiveHeaderBar>
        }
      />
      <Route
        path="reset"
        element={
          <ResponsiveHeaderBar>
            <ResetPassword />
          </ResponsiveHeaderBar>
        }
      />
      <Route
        path="verification"
        element={
          <ResponsiveHeaderBar>
            <VerificationCode />
          </ResponsiveHeaderBar>
        }
      />
      <Route
        path="*"
        element={
          <ResponsiveHeaderBar>
            <PageNotFound />
          </ResponsiveHeaderBar>
        }
      />
    </Routes>
  )
}

export default User
