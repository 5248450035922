// Source - https://gist.github.com/rmorse/426ffcc579922a82749934826fa9f743

import { useEffect, useCallback } from 'react'
import history from 'lib/history'

export function useBlocker(blocker, when = true) {
  useEffect(() => {
    if (!when) return

    const unblock = history.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock()
          tx.retry()
        },
      }

      blocker(autoUnblockingTx)
    })

    return unblock
  }, [blocker, when])
}

export default function usePrompt(message, when = true) {
  const blocker = useCallback(
    (tx) => {
      // To unblock prompt while navigating, pass unblockPrompt: true in location.state
      if (tx.location?.state?.unblockPrompt) {
        return tx.retry()
      }
      if (window.confirm(message)) tx.retry()
    },
    [message]
  )

  useBlocker(blocker, when)
}
