import { useTranslation } from 'react-i18next'

import AlertDialog, { AlertDialogProps } from 'components/AlertDialog'
import Alert from 'components/mui/Alert'

export type MutationModalProps = AlertDialogProps & {
  isLoading: boolean
  isError: boolean
  children?: React.ReactNode
}

function MutationModal({
  isLoading,
  isError,
  children,
  ...props
}: MutationModalProps) {
  const { t } = useTranslation()

  return (
    <AlertDialog {...props} isLoading={isLoading}>
      {children}
      {isError && <Alert title={t('common.errorQuery')} sx={{ mt: 2 }} />}
    </AlertDialog>
  )
}

export default MutationModal
