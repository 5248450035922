import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Typography from '@mui/material/Typography/Typography'

import { useAuth } from 'app/Auth'
import Button from 'components/mui/Button'
import Container from 'components/mui/Container/Container'
import ResponsiveHeaderBar from 'components/headerBar/ResponsiveHeaderBar'

function PageNotFound() {
  const { t } = useTranslation()
  const { isLoggedIn, logoutMutation } = useAuth()
  const navigate = useNavigate()

  return (
    <ResponsiveHeaderBar>
      <Container sx={{ mt: 4 }}>
        <Typography variant="h1" textTransform="uppercase" textAlign="center">
          {t('common.permissionDenied.title')}
        </Typography>
        <Typography variant="h2" textAlign="center" my={4}>
          {t('common.permissionDenied.description')}
        </Typography>

        <Button onClick={() => navigate(-1)}>
          {t('common.permissionDenied.primaryButton')}
        </Button>
        {isLoggedIn && (
          <Button
            variant="outlined"
            onClick={() => logoutMutation.mutate()}
            sx={{ mt: 2 }}
          >
            {t('common.permissionDenied.secondaryButton')}
          </Button>
        )}
      </Container>
    </ResponsiveHeaderBar>
  )
}

export default PageNotFound
