import { useField } from 'formik'

import MuiSlider, { SliderProps } from '@mui/material/Slider'

type Props = SliderProps & {
  hideMarks?: boolean
  name: string
}

function Slider({ hideMarks = true, sx, name, ...props }: Props) {
  const [, , helpers] = useField(name)

  const handleSliderOnChange = (e: Event) => {
    const { value } = e.target as HTMLInputElement
    helpers.setValue(value)
  }

  return (
    <MuiSlider
      key={name}
      name={name}
      valueLabelDisplay="on"
      onChange={handleSliderOnChange}
      sx={{
        '& .MuiSlider-mark': {
          display: hideMarks ? 'none' : 'inline',
        },
        ...sx,
      }}
      {...props}
    />
  )
}

export default Slider
