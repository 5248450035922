import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography/Typography'
import Button from 'components/mui/Button'
import Container from 'components/mui/Container/Container'

function PageNotFound() {
  const { t } = useTranslation()

  return (
    <Container isCentered sx={{ mt: 4 }}>
      <Typography sx={{ fontSize: '8rem', lineHeight: 1 }}>
        {t('common.404')}
      </Typography>
      <Typography variant="h1" textTransform="uppercase" textAlign="center">
        {t('common.pageNotFound')}
      </Typography>
      <Typography variant="h4" textAlign="center" my={5}>
        {t('common.pageNotFoundDescription')}
      </Typography>
      <Button to="/">{t('common.goHome')}</Button>
    </Container>
  )
}

export default PageNotFound
