import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import translationEN from 'locale/translations/en.json'
import { SUPPORTED_LANGUAGES } from 'lib/config'

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

const resources = {
  en: {
    translation: translationEN,
  },
}

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng',
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: options,
    fallbackLng: SUPPORTED_LANGUAGES[0],
    supportedLngs: SUPPORTED_LANGUAGES,
    interpolation: { escapeValue: false },
  })

export default i18n
