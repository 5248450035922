import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import Slider from 'components/mui/Slider'
import TextInput from 'components/mui/TextInput'
import theme from 'styles/customTheme'

interface Props {
  fieldName: string
  helperText?: string
  isTextInput?: boolean
  maxValue?: number
  minValue?: number
  value: number
}

function CurrencySlider({
  fieldName,
  helperText,
  isTextInput = true,
  value,
  maxValue = 100,
  minValue = 0,
  ...props
}: Props) {
  const { t } = useTranslation()

  const handleSliderValueLabelFormat = (value: number) =>
    `${t('common.intlCurrencySimplifiedFormat', {
      value: value,
    })}`

  return (
    <Grid container>
      {isTextInput && (
        <TextInput
          id={fieldName}
          name={fieldName}
          variant="outlined"
          hiddenLabel
          helperText={helperText}
          placeholder={t('common.decimalPlaceholder')}
          value={value}
          inputMode="decimal"
          delimiter="$"
          required
          showErrorHelperText={false}
          showErrorOnTouchedOnly={false}
          isNumericFormat
          sx={{
            mb: isTextInput ? 1 : 5,
            '.MuiInputAdornment-root .MuiTypography-root': {
              fontSize: theme.typography.h1,
              color: 'secondary.darkest',
            },
            '.MuiInputBase-root': {
              borderRadius: '6px',
              fontSize: theme.typography.h1,
              color: 'secondary.darkest',
            },
            '.MuiFormHelperText-root': {
              fontSize: theme.typography.body1,
              mx: 0,
            },
            '.MuiOutlinedInput-input': {
              padding: 1,
            },
          }}
        />
      )}
      <Slider
        value={value ?? 0}
        valueLabelFormat={handleSliderValueLabelFormat}
        valueLabelDisplay={isTextInput ? 'off' : 'on'}
        name={fieldName}
        max={maxValue}
        min={minValue}
        {...props}
      />
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography>
            {t('common.intlCurrencySimplifiedFormat', {
              value: minValue,
            })}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            {t('common.intlCurrencySimplifiedFormat', {
              value: maxValue,
            })}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CurrencySlider
