import styles from './errorFallback.module.scss'

interface Props {
  error?: Error
  title?: string
  description?: string
  primaryActionText?: string
  secondaryActionText?: string
}

//NOTE: ErrorFallback can't have context dependencies
function ErrorFallback({
  error,
  title = 'Something went wrong',
  description,
  primaryActionText = 'Reload page',
  secondaryActionText = 'Return to Dashboard',
}: Props) {
  error && console.log('ErrorFallback', error?.message)

  const reloadPage = () => {
    window.location.reload()
  }

  return (
    <div role="alert" className={styles.container}>
      <h1>{title}</h1>
      {description && <p>{description}</p>}

      <button
        type="button"
        className={styles.primaryAction}
        onClick={reloadPage}
      >
        {primaryActionText}
      </button>
      <a href="/" className={styles.secondaryAction}>
        {secondaryActionText}
      </a>
    </div>
  )
}

export default ErrorFallback
