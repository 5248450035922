import { useState, useEffect } from 'react'

export function useSteps(initialStep = 0) {
  const [activeStep, setActiveStep] = useState(initialStep)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  const handleNext = () => setActiveStep(activeStep + 1)

  const handleBack = () => setActiveStep(activeStep - 1)

  const handleBackToStart = () => setActiveStep(0)

  return { handleBack, handleNext, activeStep, handleBackToStart }
}
