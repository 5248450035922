import { useMemo } from 'react'
import { QueryResult } from '@apollo/client'
import { UseQueryResult } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography/Typography'
import Layout from '../components/Layout/Layout'
import LoadingIndicator from '../components/LoadingIndicator'
import Alert from '../components/mui/Alert'

type Props = React.ComponentProps<typeof Alert> & {
  queries: Array<QueryResult<any, any> | UseQueryResult<any, any>>
  children: React.ReactElement<any, any>
  alertMessage?: string
}

function QueryBoundary({
  queries,
  children,
  actionButtonHandler,
  alertMessage,
  ...props
}: Props) {
  const { t } = useTranslation()

  const buttonHandler = () => {
    if (actionButtonHandler) return actionButtonHandler()

    queries.forEach((query) => {
      if (query.error) query.refetch()
    })
  }

  const isLoading = useMemo(
    () =>
      queries.some(
        (query) =>
          ('loading' in query && query.loading) ||
          ('isLoading' in query && query.isLoading)
      ),
    [queries]
  )

  const isError = useMemo(() => queries.some((query) => query.error), [queries])

  if (isLoading) return <LoadingIndicator zIndex={-1} />

  if (isError)
    return (
      <Layout maxWidth="lg">
        <Alert
          title={props.title ?? t('common.errorQuery')}
          isBackButton
          variant="standard"
          actionButtonHandler={buttonHandler}
          {...props}
        >
          <Typography>{alertMessage}</Typography>
        </Alert>
      </Layout>
    )

  return children
}

export default QueryBoundary
