// eslint-disable-next-line no-restricted-imports
import dayjs from 'dayjs'

import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(advancedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(duration)
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.utc()

export const yearMonthDayDateFormat = 'YYYY-MM-DD'
export const monthDayYearDateFormat = 'MMM D, YYYY'
export const fullMonthDayYearDateFormat = 'MMMM D, YYYY'
export const monthDayYearSimplifiedFormat = 'MM/DD/YY'
export const monthDayFullYearSimplifiedFormat = 'MM/DD/YYYY'
export const monthDayYearTimeTimezoneFormat = 'MM/DD/YYYY [at] h:mm A (z)'

export default dayjs
