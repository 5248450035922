import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18n from 'locale/i18n'

import MuiAlert, { AlertProps } from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Grid from '@mui/material/Grid/Grid'

import Button from 'components/mui/Button'

type Props = AlertProps & {
  title?: string
  actionButtonHandler?: () => void
  actionButtonText?: string
  isBackButton?: boolean
}

function Alert({
  title,
  actionButtonHandler,
  actionButtonText = i18n.t('common.tryAgain'),
  variant = 'outlined',
  severity = 'error',
  children,
  isBackButton = false,
  ...props
}: Props) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <MuiAlert
      variant={variant}
      severity={severity}
      {...props}
      sx={{
        borderColor: `${severity}.main`,
        backgroundColor:
          variant === 'standard' ? `${severity}.light` : 'default',
        borderRadius: '6px',
        '& .MuiAlert-icon': { color: `${severity}.main` },
        '& .MuiAlert-message': { flex: 1 },
        color: variant === 'standard' ? `${severity}.dark` : 'inherit',
        ...props.sx,
      }}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {children}

      {(actionButtonHandler || isBackButton) && (
        <Grid container mt={2} justifyContent="flex-end" columnGap={2}>
          {isBackButton && (
            <Button
              mode="back"
              color={severity}
              variant="contained"
              disableElevation
              onClick={() => navigate(-1)}
            >
              {t('common.goBack')}
            </Button>
          )}
          {actionButtonHandler && (
            <Button
              color={severity}
              variant="outlined"
              onClick={() => actionButtonHandler()}
            >
              {actionButtonText}
            </Button>
          )}
        </Grid>
      )}
    </MuiAlert>
  )
}

export default Alert
