import ArrowBack from '@mui/icons-material/ArrowBack'
import ArrowForward from '@mui/icons-material/ArrowForward'
import MuiLoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton'
import { baseButtonStyling } from 'components/mui/Button'

type Props = LoadingButtonProps & {
  mode?: 'back' | 'forward'
  iconColor?: string
}

function ArrowButton({
  variant = 'contained',
  mode,
  iconColor = 'inherit',
  ...props
}: Props) {
  return (
    <MuiLoadingButton
      {...props}
      disableElevation
      variant={variant}
      sx={{ borderRadius: baseButtonStyling.borderRadius, py: 2, ...props.sx }}
    >
      {mode === 'forward' && <ArrowForward sx={{ color: iconColor }} />}
      {mode === 'back' && <ArrowBack sx={{ color: iconColor }} />}
    </MuiLoadingButton>
  )
}

export default ArrowButton
