import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography/Typography'

import Button from 'components/mui/Button'
import Layout from 'components/Layout/Layout'
import Link from 'components/mui/Link'

function LandingPage() {
  const { t } = useTranslation()

  return (
    <Layout hasLogo isSplash sx={{ p: 0 }}>
      <Grid container item gap={{ xs: 1, sm: 3 }} xs={12}>
        <Grid item xs={12} mt={{ xs: -2, sm: 3 }}>
          <Typography
            variant="h1"
            color="white"
            sx={{ fontSize: '2.5rem', lineHeight: '3.75rem' }}
          >
            {t('common.splash.title')}
          </Typography>
        </Grid>
        <Grid item maxWidth="490px">
          <Typography
            variant="h3"
            color="white"
            mb={4}
            sx={{ fontWeight: 400, lineHeight: '1.875rem' }}
          >
            {t('common.splash.description')}
          </Typography>
        </Grid>
        <Grid item xs={12} mb={{ xs: 1, sm: 0 }}>
          <Button mode="forward" to="../login/create" isLightHover>
            {t('common.splash.button')}
          </Button>
        </Grid>
        <Grid item xs={12} mb={2} display="flex">
          <Typography color="white" mr={0.5}>
            {t('common.splash.signIn')}
          </Typography>
          <Link to="../login" color="#fff" variant="body2">
            {t('user.login.logIn')}
          </Link>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default LandingPage
