import { useTranslation } from 'react-i18next'

import Typography from '@mui/material/Typography/Typography'

import { useUser } from 'app/User'
import Button from 'components/mui/Button'
import { paperShape } from 'components/mui/Paper/Paper'
import Layout from 'components/Layout/Layout'

function RoleSelect() {
  const { t } = useTranslation()
  const {
    data,
    roles: { userRoles },
    roleNavigationConfig,
  } = useUser()

  return (
    <Layout sx={{ px: 0 }}>
      <Typography variant="h2" component="h1" mb={3} textTransform="capitalize">
        {t('roleSelect.heading', { firstName: data.firstName })}
      </Typography>

      <Typography variant="body2" my={2}>
        {t('roleSelect.subHeading')}
      </Typography>

      {userRoles?.map((role) => {
        const roleConfig = roleNavigationConfig[role]

        if (!roleConfig) {
          return null
        }

        return (
          <Button
            key={role}
            mode="forward"
            variant="text"
            onClick={roleConfig.action}
            iconColor="primary.main"
            fullWidth
            sx={{
              ...paperShape,
              backgroundColor: 'white',
              color: 'secondary.darkest',
              py: 1.5,
              my: 0.5,
              justifyContent: 'space-between',
              textAlign: 'left',
            }}
          >
            <Typography variant="h4">{roleConfig.label}</Typography>
          </Button>
        )
      })}
    </Layout>
  )
}

export default RoleSelect
