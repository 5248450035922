import classNames from 'classnames'
import Box from '@mui/material/Box/Box'
import MuiContainer, { ContainerProps } from '@mui/material/Container'
import { FullLogoLinkButton } from 'components/LogoLinkButtons'
import styles from './layout.module.scss'

type Props = ContainerProps & {
  hasLogo?: boolean
  isContentCentered?: boolean
  isSplash?: boolean
  isSideBarStepper?: boolean
}

function Layout({
  children,
  hasLogo = false,
  isContentCentered = false,
  isSplash = false,
  isSideBarStepper = false,
  maxWidth = 'xs',
  ...props
}: Props) {
  return (
    <MuiContainer
      className={classNames(
        { [styles.splashContainer]: isSplash },
        styles.container
      )}
      sx={{
        alignItems: isContentCentered ? 'center' : 'left',
        mx: 'auto',
      }}
      maxWidth={isSplash ? false : maxWidth}
    >
      <Box
        mt={6}
        mx={isSplash ? { xs: 0, sm: 5 } : 0}
        sx={{
          px: isSideBarStepper ? { md: 3 } : 0,
          pt: isSideBarStepper ? { md: 2 } : 0,
          ...props.sx,
        }}
      >
        {hasLogo && (
          <Box sx={{ mb: 6, textAlign: isSplash ? 'left' : 'center' }}>
            <FullLogoLinkButton isWhiteLogo={isSplash} />
          </Box>
        )}
        {children}
      </Box>
    </MuiContainer>
  )
}

export default Layout
