import { ApolloClient, InMemoryCache } from '@apollo/client'
import { ApolloLink } from 'apollo-link'
import { createAuthLink } from 'aws-appsync-auth-link'
import { createHttpLink } from 'apollo-link-http'
import { SentryLink } from 'apollo-link-sentry'

import { APOLLO_CONFIG } from 'lib/config'

const { url, auth, region } = APOLLO_CONFIG

const link = ApolloLink.from([
  new SentryLink(),
  createAuthLink({ url, region, auth }),
  createHttpLink({ uri: url }),
])

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
})
