import { Amplify, Auth } from 'aws-amplify'

import { AWS_AMPLIFY } from 'lib/config'

export function initializeAmplify(config = AWS_AMPLIFY) {
  Amplify.configure(config)
}

export async function getAccessTokenJWT() {
  return (await Auth.currentSession()).getAccessToken().getJwtToken()
}
