import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

interface Props {
  zIndex?: number
  sx?: any
}
function LoadingIndicator({ zIndex = 0, sx }: Props) {
  return (
    <Backdrop
      sx={
        sx ?? {
          backgroundColor: 'rgba(255,255,255,0.9)',
          zIndex,
        }
      }
      open
    >
      <CircularProgress color="primary" />
    </Backdrop>
  )
}

export default LoadingIndicator
